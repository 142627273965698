.cursos{
    display: flex;
    align-items: center;
    padding: 50px;
    height: 100%;
    width: 100%;
    background-color: #1c2b6c;
    background-size: cover;
       
}
.cursos-h1{
  text-align: center;
  font-family: 'Rowdies', cursive;
 
}

.container-curso {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  
}

.card-curso{
  position: relative;
  color: #ffffff;
  cursor: pointer;
  width: 22rem;
  text-align: center;
  
  
  
}
.title>p{
  position: relative;
  font-family: 'Rowdies', cursive;
  
}
.title>p::before{
  position: absolute;
  content: '';
  width: 95px;
  height: 2px;
  background-color: #ffffff;
  bottom: -10px;
  margin-left: 70px;
  
  
}

.imagem{
  opacity: 1;
  display: block;
  transition: all 0.3s ease-in-out;
  width:22rem;
  height: 18rem;
  border-radius: 30px 0 30px 0;

}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.card-curso:hover .imagem {
  opacity: 0.3;
}

.card-curso:hover .middle {
  opacity: 1;
}

.text-curso {
  background-color: #0b0c0c41;
  color: white;
  width: 22rem;
  height: 18rem;
  font-size: 14px;
  padding: 30px;
  border-radius: 20px 0 20px 0;
   
}

.cursos-p{
  font-family: 'Rowdies', cursive;
  text-align: center;
  font-size: 1rem;
  color: #ffffff;
 
}
.cursos-p::before{

  text-align: center;
  position: absolute;
  content: '';
  width: 60px;
  height: 2px;
  background-color: #ffffff;
  bottom: -7px;
  margin-left: 60px;
  
  
  }
.cursos-h1{
  color: #ffffff;
}



.btn-cursos {
  color: #ffffff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b22234;
  border: 2px solid #ffffff;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 150px;
  height: 65px;
  margin: 40px auto;
}

.btn-cursos a {
  text-decoration: none;
  color: #ffffff;
}

.btn-cursos:hover {
  background-color: #ffffff;
  border: 2px solid #b22234;
}

.btn-cursos:hover a {
  color: #b22234;
}

@media (max-width: 800px) {
 .cursos{
  display: flex;
  flex-direction: column;
  
 }

     
} 
 





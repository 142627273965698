.template {
height: 120vh;
position: relative;


 
} 
.template::before{
    content: "";
    position: absolute;
    inset: 0;
    background-image: url("./Img/fundo-2.webp");
    background-size: cover;
    z-index: -1;
    opacity: 0.7;
    background-attachment: fixed;
    border-bottom-left-radius: 230px;
    border-bottom-right-radius:230px;
    
    
}
.div-geral-carrossel{
    width: 40%;
    text-align: center;
}

.text1-carrossel {
    font-size: 40px;
    padding: 80px 50px 0;
    color: #1c2b6c;
    font-weight: 800;
    
}

.text2-carrossel {
    font-size: 50px;
     color: #b22234;
    font-weight: 800;
   
    
}

.text3-carrossel {
    font-size: 20px;
     
}

.btn-home {
    color: #ffffff;
    font-weight: 700;
    background-color: #b22234;
    border: 2px solid #ffffff;
    border-radius: 25px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 200px;
    height: 80px;
    margin: 40px;
}

.btn-home a {
    font-size: 1.5em;
    text-decoration: none;
    color: #ffffff;
}

.btn-home:hover {
    background-color: #ffffff;
    border: 2px solid #b22234;
}

.btn-home:hover a {
    color: #b22234;
}

#btn-flutuante{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 5px;
    bottom: 10px;
    z-index: 1;
    cursor: pointer;
    text-align: center;
    font-size: 4em;
    padding: 5px;
    transition: font-size 0.5s;
}

#btn-flutuante a{
    text-decoration: none;
    color: #ffffff;
}

#btn-flutuante:hover{
   font-size: 5em;   
}


@media (max-width: 800px) {
    .template {
       display: flex;
       height: 50%;
       justify-content: center;
       align-items: center;
       flex-direction: column;
       color:#b22234;     
    
       
    } 
    .template::before{

        background-image: url("../Carrossel/Img/bigban.jpg");
        opacity: 0.7;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
    }

    .div-geral-carrossel{
        width: 100%;
        text-align: center;
    }

    .text1-carrossel {
        font-size: 30px;
        text-align: center;
    }
    .text2-carrossel {
        font-size: 40px;
        text-align: center;
    }
    .text3-carrossel {
        font-size: 20px;
        text-align: center; 
        color: black;
   
    }
}


.metodologia{
   display: flex;
   flex-direction: column;
   height: 100%;
   width: 100%;
   padding: 50px 40px;
   background-size: cover;
  
   
   }

   .logo-biblia{
    width: 330px;
   
   }
   .metodologia>h1{
      font-family: 'Rowdies', cursive;
      text-align: center;
      
   }
        
   .metodologia>p{
      position: relative;
      font-family: 'Rowdies', cursive;
      text-align: center;
      font-size: 1.2rem;
     
   }

   .metodologia>p::before{
      position: absolute;
      content: '';
      width: 95px;
      height: 3px;
      background-color: black;
      bottom: -10px;
      margin-left: 125px;
   }
   .video-youtube-metodologia{
      display: flex;
      justify-content: center;
      padding-top: 50px;
   }
  
    .button-ingles{
      color: #b22234;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      border: 2px solid #b22234;
      border-radius: 40px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      width: 200px;
      height: 84px;
      margin-top: 30px;
           
   }

   .button-ingles a{
      text-decoration: none;
      color: #b22234;
         
   }
   
   .button-ingles:hover{
      background-color: #b22234;
      border: 2px solid #ffffff;
      color: #ffffff;
   }
   .button-ingles:hover a{
      color: #ffffff;
   }

   .text1{
      padding-top: 50px;
      font-family: 'Rowdies', cursive;

   }

   @media (max-width: 800px) {
      .metodologia>h1{
        display: flex;
        justify-content: center;
        align-items: center;
         font-size: 2rem;
         margin-left: 2px;
         
      }
      .metodologia>{
       text-align: center;
      
       }
       .video-youtube-metodologia{
         width: 18rem;
       }

       .react-player{
         width:300px;
         height: 300px;
       }
  
  }
   
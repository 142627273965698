.sobre {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 50px 40px;
    background-size: cover;

}

.sobre>h2 {
    font-family: 'Rowdies', cursive;
    font-size: 30px;
    color: #ffffff;
    line-height: 1.8;
    border-bottom: 2px solid #ffffff;
    width: 250px;
}

.text-sobre {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

}

.text {
    
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-size: 1.1rem;
    font-weight: 400;
    margin-top: 50px;
    padding: 20px;
    border-radius: 20px;
}

.text-h1{
    position: relative;;
    font-family: 'Rowdies', cursive;
    align-items: center;
    text-align: center;
    font-size: 4rem;
    
    
}

.text-h1::before{
position: absolute;
content: '';
width: 60px;
height: 5px;
background-color: #1c2b6c;
bottom: 4px;

}


.img-daize {
    width: 350px;
    height: 400px;
    border-radius: 20px;

}

.button-sobre-mim {
    color: #fff;
    font-weight: 700;
    background-color: #1c2b6c;
    border: none;
    border-radius: 30px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 200px;
    height: 75px;

}

.button-sobre-mim:hover {
    color: #fff;
    background-color: #72131e;
}
.youtube-sessao{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding:40px;
    background-color: #acb5df75;
    color: #1c2b6c;
    
}

.youtube-sessao h1{
    position: relative;
    font-family: 'Rowdies', cursive;
    font-size: 3rem;
    text-align: center;
   
}

.youtube-container{
    display: flex;
    flex-direction: column;
    
}

.youtube-videos{
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 padding-top: 50px;


}

iframe{
    border-radius: 20px;
}

.button-youtube{
color: #ffffff;
font-weight: 700;
background-color: #b22234;
border: 2px solid #ffffff;
border-radius: 30px;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
width: 200px;
height: 84px;
margin-top: 50px;

}

.button-youtube:hover{
border: none;
color: #fff;
background-color: #72131e;


}


.comentarios {
    display: flex;
    align-items: center;
    padding: 50px 40px;
    background-color:  #acb5df75;
    justify-content:space-around;
    flex-wrap: wrap;
       
}

.comentarios>h1 {
    display: flex;
    position: relative;
    font-family: 'Rowdies', cursive;
    font-size: 3rem;
    text-align: center;
    align-items: center;
    
}
.comentarios>h1::before{
    content: "";
    position: absolute;
    background-image: url("./img/Aspas.png");
    height: 200px;
    width: 235px;
    inset: 0;
    background-size: contain;
    z-index: -1;
    opacity: 0.7;
    
    
   
}

.comments-carrossel {
    display: flex;
    flex-wrap: nowrap; /* Impede que os itens quebrem a linha */
    width: 50%;
    padding: 20px;
    justify-content: flex-start;
    
}

.card-comentarios {
    height: 400px;
    margin-right: 15px; /* Espaço entre os cartões */
    padding: 20px;
    background: #1c2b6c;
    font-family: 'Rowdies', cursive;
    color: #ffff;
     /* Impede que os cartões se estiquem */
    
}
.estados{
    font-size: 15px;
}
.img-estrela{
    width: 100px;
    margin-top: -30px;
}

.comment-p {
    padding-top: 20px;
    color: #ffff;
    text-align: left;
}

*::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

*::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: transparent;
}

*::-webkit-scrollbar-track:hover {
    background-color: #B8C0C2;
}

*::-webkit-scrollbar-track:active {
    background-color: #B8C0C2;
}

*::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #DC0B0B;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #D50505;
}

*::-webkit-scrollbar-thumb:active {
    background-color: #1712A3;
} 

@media (max-width: 800px) {
    .comments-carrossel {
        width: 250px;
    }
    .text-comentarios {
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
        height: 110px;
    }

   } 
*{
    margin: 0;
    padding: 0;
}

/* ----- Header ----- */
 
.div-logo{
    display: flex;
    flex-direction: row;
    gap: 20px;
    line-height: 10px;
    text-align: center;
    align-items: center;
}

.fonte{
    width: 250px;
    padding: 10px;
    
}


.me-auto {
   margin-left:30% ;    
   gap: 20px;
   font-weight: 700;

}

.nav-link{
    color: rgba(0, 0, 0, 0.986);
  
}

.nav-link a{
    text-decoration: none;
    color: rgba(0, 0, 0, 0.884);
    border-bottom: 2px solid rgba(0,0,0,.55);
}
.nav-link a:hover{
    text-decoration: none;
    color: rgb(66, 64, 64);
    
}

.img-sociais{
    width: 50px;
    cursor: pointer;
    
}

.p-sociais{
    font-weight: 700;
    font-size: 14px;
    margin-left: 20px;
}


.nav-link .active {
    text-decoration: none;
    color: #b22234!important; 
    border-bottom: 2px solid #b22234!important;
}

@media (max-width: 990px) {
.fonte{
   width: 180px;
   height: 100px;
}
.me-auto{
    background-color: #ffffff94;
    border-radius: 10px;
    text-align: center;
}
}






*{
    margin: 0;
    padding: 0;
}

.container-valores{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}
.linha{
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin: 10px;
    border-radius: 5px;
    margin-bottom: 50px;
   
    
}


.link-valores{
padding: 0.5rem 2.5rem;
background-color:#b22234;
border-radius: 20px;
color: #ffff;

}

.link-valores a{
    text-decoration: none;
    color: #ffff;
}
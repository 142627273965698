.footer {
    background-color: #1c2b6c;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    position: relative;
    width: 100%;
    bottom: 0;
    
}
.redes-sociais>a{
  color: #FFFFFF;
  margin: 15px;
  font-size: 25px;
}

.p-sociais-footer{
  font-weight: 700;
  font-size: 16px;
  margin-left: 20px;
}

#link-topo{
  width: 50px;
  height: 50px;
  text-decoration: none;
  color: #FFFFFF;
  background-color: #1c2b6c;
  font-size: 2rem;
  position: absolute;
  right: 10px;
  bottom: 250px;
  border-radius: 20%;
  text-align: center;
  font-weight: 700;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}


.employment{
    margin-top: 20px;
    
}

.employment p{
    
    margin-bottom: 2px;
    font-size: 12px;
}

